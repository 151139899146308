[
    {
      "command":"help",
      "responseType":"list",
      "value":[
        "<code>a-propos</code> : Affiche les informations me concernant",
        "<code>clear</code> : Nettoie le terminal",
        "<code>experiences</code> : Affiche la liste de mes expériences",
        "<code>get cv</code> : Télécharge le CV",
        "<code>help</code> : Affiche l'aide",
        "<code>hobby</code> : Affiche la liste de mes passes temps",
        "<code>projets-perso</code> : Affiche la liste de mes projets personnels",
        "<code>dark/light</code> : Change le thème de la page",
        "<em>Vous pouvez utiliser la touche TAB afin de compléter une commande</em>",
        "<em>Vous pouvez retrouver les anciennes commandes avec les flèches haut et bas.</em>"
      ]
    },
    {
      "command":"a-propos",
      "responseType":"code",
      "value":[
        "{",
        "   \"nom\" : \"Gregory Lebreton\",",
        "   \"poste\" : \"Formateur Devops\",",
        "   \"experience\" : \"6\",",
        "   \"ville\" : \"Paris, France\"",
        "}"
      ]
    },
    {
      "command":"experiences",
      "responseType":"table",
      "headers":[
        "Date",
        "Client",
        "Description",
        "Tech"
      ],
      "rows":[
        [
          "maintenant<br/>03/2021",
          "<br/><em>Le garage numérique</em>",
          "Formateur Devops pour le CNAM,<br/>administrateur système et intégrateur pour le Garage",
          "Docker<br/>Python<br/>Bash"
        ],
        [
          "09/2019<br/>06/2019",
          "<br/><em>Safran, S.A.E</em>",
          "Mise en place d'une plateforme mettant en relation les<br/>différents acteurs de la DSI sur une plateforme logicielle.",
          "Docker<br/>Kubernetes<br/>Jenkins"
        ],
        [
          "03/2019<br/>12/2017",
          "PHP dev<br/><em>Leading Frog</em>",
          "Module PHP permettant l'envoie de cartes postales<br/>numériques avec implémentation API Stripe.",
          "PHP<br/>JavaScript<br/>SQL"
        ]
      ]
    },
    {
      "command":"hobby",
      "responseType":"list",
      "value":[
        "Musique: Skateboard, Unity, VR",
        "Programmation: Python, bash, PHP, C#",
        "Autre: Famille, Cinéma, Environnement"
      ]
    },
    {
      "command":"projets-perso",
      "responseType":"table",
      "headers":[
        "Nom",
        "Description",
        "Tech",
        "Liens"
      ],
      "rows":[
        [
          "Personal website<br/>(2021)",
          "Site web personnel me permettant de montrer mes projets et tester des applicatifs<br/>",
          "PHP/JS",
          "<a href=\"https://www.gregandev.fr\" target=\"blank\">Lien</a>"
        ],
        [
          "LA2029<br/>(2023)",
          "FPS shooter en VR dans le monde de Terminator.",
          "C# WPF",
          "<a href=\"https://www.gregandev.fr/page/la-2029\" target=\"blank\">Lien</a>"
        ],
        [
          "GoldeneyeVR<br/>(2020)",
          "Implémentation VR au célèbre jeux de 1997.",
          "C# WPF",
          "<a href=\"https://www.gregandev.fr/page/goldeneyevr\" target=\"blank\">Lien</a>"
        ]
      ]
    }
]
